var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSection("verify_data")
    ? _c(
        "b-card",
        { attrs: { id: "form-data-section", "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-4",
                  modifiers: { "verify-order-accordion-4": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("verify_data") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center header-container",
                },
                [
                  _c("div", { staticClass: "header-left" }, [
                    _c("h4", [
                      _vm._v("\n          Information To Be Filed\n        "),
                    ]),
                    _c(
                      "h5",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sectionIsComplete("verify_data"),
                            expression: "sectionIsComplete('verify_data')",
                          },
                        ],
                        staticClass: "information-verified",
                      },
                      [
                        _c("check-circle", {
                          staticClass: "check-circle",
                          attrs: { width: "20", height: "20" },
                        }),
                        _vm._v("\n          Information Verified\n        "),
                      ],
                      1
                    ),
                  ]),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-4",
              attrs: {
                id: "verify-order-accordion-4",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "verify_data",
              },
            },
            [
              _c("verify-order-form", {
                ref: "verify-order-form",
                attrs: { item: _vm.currentObject },
                on: {
                  loading: function ($event) {
                    _vm.verifyOrderFormLoaded = false
                  },
                  loaded: function ($event) {
                    _vm.verifyOrderFormLoaded = true
                  },
                },
              }),
              _vm.verifyOrderFormLoaded
                ? _c(
                    "b-card-footer",
                    {
                      staticClass: "accordion-footer",
                      class: {
                        "flex-row-reverse":
                          _vm.$refs["verify-order-form"].editMode,
                      },
                      attrs: { "footer-tag": "footer", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          class: _vm.$refs["verify-order-form"].editMode
                            ? "button-next"
                            : "button-link",
                          attrs: { "aria-label": "update button" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs[
                                "verify-order-form"
                              ].editButtonClicked({
                                showErrors:
                                  _vm.$refs["verify-order-form"].editMode,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$refs["verify-order-form"].editMode
                                  ? "Save"
                                  : "Make Changes"
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm.$refs["verify-order-form"].editMode
                        ? _c(
                            "b-button",
                            {
                              staticClass: "button-link",
                              attrs: { "aria-label": "cancel button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs[
                                    "verify-order-form"
                                  ].cancelButtonClicked()
                                },
                              },
                            },
                            [_vm._v("\n        Cancel\n      ")]
                          )
                        : _vm._e(),
                      !_vm.$refs["verify-order-form"].editMode
                        ? _c(
                            "b-button",
                            {
                              staticClass: "button-next",
                              attrs: { "aria-label": "looks good button" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmData()
                                },
                              },
                            },
                            [_vm._v("\n        Looks Good!\n      ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }