<template>
  <b-card
    v-if="showSection('verify_data')"
    id="form-data-section"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-4
      header-tag="header"
      class="accordion-header"
      :class="{ disabled: sectionIsDisabled('verify_data') }"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center header-container">
        <div class="header-left">
          <h4>
            Information To Be Filed
          </h4>
          <h5 v-show="sectionIsComplete('verify_data')" class="information-verified">
            <check-circle class="check-circle" width="20" height="20" />
            Information Verified
          </h5>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-4"
      ref="verify-order-accordion-4"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'verify_data'"
    >
      <verify-order-form
        ref="verify-order-form"
        :item="currentObject"
        @loading="verifyOrderFormLoaded = false"
        @loaded="verifyOrderFormLoaded = true"
      />
      <b-card-footer
        v-if="verifyOrderFormLoaded"
        footer-tag="footer"
        class="accordion-footer"
        :class="{ 'flex-row-reverse': $refs['verify-order-form'].editMode }"
        role="tab"
      >
        <b-button
          :class="$refs['verify-order-form'].editMode ? 'button-next' : 'button-link'"
          aria-label="update button"
          @click="$refs['verify-order-form'].editButtonClicked({ showErrors: $refs['verify-order-form'].editMode })"
        >
          {{ $refs['verify-order-form'].editMode ? 'Save' : 'Make Changes' }}
        </b-button>
        <b-button
          v-if="$refs['verify-order-form'].editMode"
          class="button-link"
          aria-label="cancel button"
          @click="$refs['verify-order-form'].cancelButtonClicked()"
        >
          Cancel
        </b-button>
        <b-button
          v-if="!$refs['verify-order-form'].editMode"
          class="button-next"
          aria-label="looks good button"
          @click="confirmData()"
        >
          Looks Good!
        </b-button>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'FormDataSection',
  components: {
    FeatherIcon:      () => import('@/components/shared/FeatherIcon'),
    CheckCircle:      () => import('@images/ui/check-circle.svg'),
    VerifyOrderForm:  () => import('@/components/VerifyOrder/VerifyOrderForm'),
  },
  props: {
  },
  data() {
    return {
      verifyOrderFormLoaded: false,
    }
  },
  computed: {
    ...mapGetters('verifyOrder', [
      'currentSection',
      'sectionIsComplete',
      'sectionIsDisabled',
      'showSection',
    ]),
    ...mapGetters('schema', [
      'currentObject',
      'wrapperValid',
    ]),
  },
  async mounted() {},
  methods: {
    async confirmData() {
      if (!this.wrapperValid) {
        this.showMissingFields({ showErrors: true, forceEditMode: true })
      } else {
        await this.$refs['verify-order-form'].saveDetails()
        this.$emit('completed')
      }
    },
    showMissingFields(params) {
      this.$refs['verify-order-form'].editButtonClicked(params)
    },
  },
}
</script>

<style lang="scss">

#form-data-section {

  .accordion-header {

    div.header-container {

      .header-left {

        h5.information-verified {
          color: $ct-ui-primary !important;
          fill: $ct-ui-primary !important;
        }
      }
    }
  }

  #verify-order-accordion-4 {
    padding: 0;
    margin: 0;

    .accordion-footer {

      button {

        &:focus,
        &:active,
        &:hover {
          outline: none;
          box-shadow: none;
        }
      }

      .button-link {
        color: $ct-ui-primary !important;
        background: white;
        border: 0;

        &:hover {
          background: $ct-ui-primary-light;
        }
      }

      .button-next {
        background: $ct-ui-primary;
        border-color: $ct-ui-primary;

        &:hover {
          background: $ct-ui-primary-dark;
          border-color: $ct-ui-primary-dark;
        }
      }
    }
  }
}

</style>
